import React from "react";
import { Form, InputGroup, Offcanvas } from "react-bootstrap";
import { FooterBtnState, LanguageSetting } from "../common/Const";
import styled from "styled-components";
import KTVTable from "./FilterTable/KTVTable";
import RestaurantTable from "./FilterTable/RestaurantTable";
import PokerRoomTable from "./FilterTable/PorkerRoomTable";
import HotelTable from "./FilterTable/HotelTable";
import CasinoTable from "./FilterTable/CasinoTable";
import MoneyExchanger from "./MoneyExchanger";
import MoneyExchangerTable from "./FilterTable/MoneyExchangerTable";

type FilterCompoProps = {
  isFilterShow: boolean;
  handleFilterShow: () => void;
  langSetting: LanguageSetting;
  serchedShopName: string;
  setSerchedShopName: (serchedShopName: string) => void;
  setCenterOfMap: (position: L.LatLngTuple) => void;
  setIsMoveCenter: (isMoveCenter: boolean) => void;
  setPinId: (id: string) => void;
  pinId: string;
  setZoomOfMap: (magnification: number) => void;
  footerBtnState: FooterBtnState;
};

const FilterCompo: React.FC<FilterCompoProps> = ({
  isFilterShow,
  handleFilterShow,
  langSetting,
  serchedShopName,
  setSerchedShopName,
  setCenterOfMap,
  setIsMoveCenter,
  setPinId,
  pinId,
  setZoomOfMap,
  footerBtnState,
}) => {
  return (
    <StyledOffCanvas
      show={isFilterShow}
      onHide={handleFilterShow}
      backdrop={false}
      placement="start"
    >
      <StyledOffCanvasHeader closeButton>
        <Offcanvas.Title as={"p"}>
          {langSetting === "JA" ? "フィルター" : "Filter"}
          {/* {footerBtnState === "Restaurant" && (
                <>
                  <Badge
                    bg={restaurantTagState === "JAPANESE" ? "primary" : "light"}
                    text={restaurantTagState === "JAPANESE" ? "light" : "dark"}
                    className="ms-3"
                    onClick={() => setRestaurantTagState("JAPANESE")}
                  >
                    日本食
                  </Badge>
                  <Badge
                    bg={restaurantTagState === "CHINESE" ? "primary" : "light"}
                    text={restaurantTagState === "CHINESE" ? "light" : "dark"}
                    className="ms-3"
                    onClick={() => setRestaurantTagState("CHINESE")}
                  >
                    中華
                  </Badge>
                  <Badge
                    bg={restaurantTagState === "KOREAN" ? "primary" : "light"}
                    text={restaurantTagState === "KOREAN" ? "light" : "dark"}
                    className="ms-3"
                    onClick={() => setRestaurantTagState("KOREAN")}
                  >
                    韓国
                  </Badge>
                  <Badge
                    bg={
                      restaurantTagState === "PHILLIPINE" ? "primary" : "light"
                    }
                    text={
                      restaurantTagState === "PHILLIPINE" ? "light" : "dark"
                    }
                    className="ms-3"
                    onClick={() => setRestaurantTagState("PHILLIPINE")}
                  >
                    ﾌｨﾘﾋﾟﾝ
                  </Badge>
                </>
              )} */}
        </Offcanvas.Title>
      </StyledOffCanvasHeader>
      <StyledOffCanvasBody>
        <InputGroup size="sm">
          <Form.Control
            placeholder={
              langSetting === "JA"
                ? "検索したい店名を入力してください"
                : "Shop name here"
            }
            value={serchedShopName}
            aria-label="searchShopName"
            aria-describedby="searchShopName"
            onChange={(e) => setSerchedShopName(e.target.value)}
          />
        </InputGroup>
        <StyledTableWrapper>
          {footerBtnState === "KTV" && (
            <KTVTable
              handleFilterShow={handleFilterShow}
              langSetting={langSetting}
              serchedShopName={serchedShopName}
              setCenterOfMap={setCenterOfMap}
              setIsMoveCenter={setIsMoveCenter}
              setPinId={setPinId}
              setZoomOfMap={setZoomOfMap}
            />
          )}
          {footerBtnState === "Restaurant" && (
            <RestaurantTable
              handleFilterShow={handleFilterShow}
              langSetting={langSetting}
              serchedShopName={serchedShopName}
              setCenterOfMap={setCenterOfMap}
              setIsMoveCenter={setIsMoveCenter}
              setPinId={setPinId}
              setZoomOfMap={setZoomOfMap}
            />
          )}
          {footerBtnState === "Hotel" && (
            <HotelTable
              handleFilterShow={handleFilterShow}
              langSetting={langSetting}
              serchedShopName={serchedShopName}
              setCenterOfMap={setCenterOfMap}
              setIsMoveCenter={setIsMoveCenter}
              setPinId={setPinId}
              setZoomOfMap={setZoomOfMap}
            />
          )}
          {footerBtnState === "PokerRoom" && (
            <PokerRoomTable
              handleFilterShow={handleFilterShow}
              langSetting={langSetting}
              serchedShopName={serchedShopName}
              setCenterOfMap={setCenterOfMap}
              setIsMoveCenter={setIsMoveCenter}
              setPinId={setPinId}
              setZoomOfMap={setZoomOfMap}
            />
          )}
          {footerBtnState === "Casino" && (
            <CasinoTable
              handleFilterShow={handleFilterShow}
              langSetting={langSetting}
              serchedShopName={serchedShopName}
              setCenterOfMap={setCenterOfMap}
              setIsMoveCenter={setIsMoveCenter}
              setPinId={setPinId}
              setZoomOfMap={setZoomOfMap}
            />
          )}
          {footerBtnState === "MoneyExchanger" && (
            <MoneyExchangerTable
              handleFilterShow={handleFilterShow}
              langSetting={langSetting}
              serchedShopName={serchedShopName}
              setCenterOfMap={setCenterOfMap}
              setIsMoveCenter={setIsMoveCenter}
              setPinId={setPinId}
              setZoomOfMap={setZoomOfMap}
            />
          )}
          {/* <StyledTable striped borderless hover variant="dark" size="sm">
            <thead>
              <tr>
                <th rowSpan={2}>
                  {langSetting === "JA" ? "店名" : "Shop name"}
                </th>
                <th colSpan={4}>
                  {langSetting === "JA" ? " 料金 (PHP)" : "Price"}
                </th>
              </tr>
              <tr>
                <th> {langSetting === "JA" ? "90min" : "90min"}</th>
                <th> {langSetting === "JA" ? "60min" : "60min"}</th>
                <th> {langSetting === "JA" ? "LD" : "LD"}</th>
                <th> {langSetting === "JA" ? "指名" : "Request"}</th>
              </tr>
            </thead>
            <tbody>
              {ktvGeoJsonData.features
                .filter(
                  (a: any) =>
                    a.properties.shopName
                      .toLowerCase()
                      .indexOf(serchedShopName.toLowerCase()) > -1
                )
                .sort((a: any, b: any) => {
                  if (
                    a["properties"]["setPrice90"] <
                    b["properties"]["setPrice90"]
                  )
                    return 1;
                  if (
                    a["properties"]["setPrice90"] >
                    b["properties"]["setPrice90"]
                  )
                    return -1;
                  return 0;
                })
                .map((row) => {
                  return (
                    <tr
                      key={`tr${row.properties.id}`}
                      onClick={() => {
                        document
                          .querySelectorAll(".leaflet-marker-icon")
                          .forEach((e) => e.classList.remove("serched-pin"));
                        setCenterOfMap(
                          row.geometry.coordinates
                            ? [
                                Number(row.geometry.coordinates[1]),
                                Number(row.geometry.coordinates[0]),
                              ]
                            : [14.571649689172614, 120.98437441430472]
                        );
                        setZoomOfMap(19);
                        setIsMoveCenter(true);
                        setPinId(`.KTV${row.properties.id}`);

                        let e = new Event("click", {
                          bubbles: true,
                          cancelable: true,
                        });
                        let pin = document.querySelector(
                          `img.leaflet-marker-icon.KTV${row.properties.id}`
                        ) as HTMLElement;
                        pin.classList.add("serched-pin");
                        pin.dispatchEvent(e);
                        handleFilterShow();
                      }}
                    >
                      <td key={`shopname${row.properties.id}`}>
                        {" "}
                        {row.properties.shopName.split("|||")[0]}
                        <span className="tag-for-search">
                          {row.properties.shopName.split("|||")[1]}
                        </span>
                      </td>
                      <td key={`90price${row.properties.id}`}>
                        {row.properties.setPrice90
                          ? row.properties.setPrice90
                          : "-"}
                      </td>
                      <td key={`60price${row.properties.id}`}>
                        {row.properties.setPrice60
                          ? row.properties.setPrice60
                          : "-"}
                      </td>
                      <td key={`ld${row.properties.id}`}>
                        {row.properties.ladiesDrink
                          ? row.properties.ladiesDrink
                          : "-"}
                      </td>
                      <td key={`lq${row.properties.id}`}>
                        {row.properties.ladiesRequest
                          ? row.properties.ladiesRequest
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </StyledTable> */}
        </StyledTableWrapper>
      </StyledOffCanvasBody>
    </StyledOffCanvas>
  );
};
const StyledOffCanvasHeader = styled(Offcanvas.Header)`
  height: 32px;
  color: white;
  font-weight: bold;
  font-family: "Darumadrop One";
`;
const StyledOffCanvasBody = styled(Offcanvas.Body)`
  height: 30px;
  padding: 6px;
`;
const StyledOffCanvas = styled(Offcanvas)`
  position: absolute;
  top: 55px;
  margin-top: 55px;
  z-index: 1010;
  height: calc(100dvh - 55px - 121px);
  width: 70% !important;
  background-color: #6c757d;
  opacity: 0.9;
`;

const StyledTableWrapper = styled.div`
  overflow-y: scroll;
  position: absolute;
  width: 98%;
  height: 80%;
  padding-right: 3px;
  margin-right: 6px;
  margin-top: 10px;

  &::-webkit-scrollbar {
    width: 2px;

    display: initial;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
`;
export default FilterCompo;
