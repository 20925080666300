import L, { Layer } from "leaflet";
import { GeoJSON } from "react-leaflet";
import { ReactElement, useState } from "react";
import {
  Button,
  Card,
  Carousel,
  Col,
  Container,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import * as ReactDOMServer from "react-dom/server";
import restaurantGeoJsonData from "../geoJson/restaurant.json";
import restaurantPin from "../icons/restaurantPin.png";
import { RestaurantTagState } from "../common/Const";

type RestaurantProps = {
  serchedShopName: string;
  serchedType: RestaurantTagState;
  langSetting: string;
};

const Restaurant: React.FC<RestaurantProps> = ({
  serchedShopName,
  serchedType,
  langSetting,
}) => {
  const [isModalShow, setIsMondalShow] = useState(false);
  const [modalImage, setModalImage] = useState<[string]>([""]);
  const handleCloseModal = () => setIsMondalShow(false);
  const onEachPoint = (point: any, layer: Layer) => {
    const element: ReactElement = (
      <Card style={{ width: "100%", border: "none" }} bg={"dark"} text="light">
        <Card.Img
          id={`Restaurant${point.properties.id}`}
          variant="top"
          src={`/images/Restaurant/${point.properties.imageName}.jpg`}
          alt={"COMING SOON"}
        />
        <Card.Body className="p-2">
          {/* <Card.Subtitle className="mt-0 mb-2">【レストラン】</Card.Subtitle> */}
          <Container>
            <Row>
              <Col xs={4}>{langSetting === "JA" ? "店舗名" : "ShopName"}</Col>
              <Col xs={8}>{point.properties.shopName}</Col>
            </Row>
            {point.properties.type && (
              <Row>
                <Col xs={4}>{langSetting === "JA" ? "ジャンル" : "Type"}</Col>
                <Col xs={8}>{point.properties.type}</Col>
              </Row>
            )}
            {point.properties.tag && (
              <Row>
                <Col xs={4}>{langSetting === "JA" ? "ジャンル" : "Genre"}</Col>
                <Col xs={8}>{point.properties.tag}</Col>
              </Row>
            )}
            {point.properties.memo && (
              <Row>
                <Col xs={4}>{langSetting === "JA" ? "備考" : "Memo"}</Col>
                <Col xs={8}>{point.properties.memo}</Col>
              </Row>
            )}
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-center">
          {" "}
          {point.properties.googleUrl && (
            <Button
              variant="outline-light"
              size="sm"
              href={
                navigator.userAgent.match(/iPhone/)
                  ? point.properties.googleLink
                  : point.properties.googleUrl
              }
              target={"_blank"}
              rel={"noreferrer"}
              color={"white!important"}
            >
              {" "}
              {langSetting === "JA" ? "ここへ行く" : "Go here"}
            </Button>
          )}
        </Card.Footer>
      </Card>
    );
    layer
      .bindPopup(`${ReactDOMServer.renderToString(element)}`, {
        maxHeight: 450,
        maxWidth: 300,
        minWidth: 270,
      })
      .on("popupopen", () => {
        document
          .getElementById(`Restaurant${point.properties.id}`)
          ?.addEventListener("click", () => {
            setIsMondalShow(true);
            point.properties.otherImages
              ? setModalImage(point.properties.otherImages.split(","))
              : setModalImage([point.properties.imageName]);
          });
      });
  };
  const markerOptions = (geoJsonPoint: any, latlng: L.LatLng) => {
    let icon = L.icon({
      iconUrl: restaurantPin,
      iconSize: [35, 53],
      iconAnchor: [17.5, 53],
      popupAnchor: [0, -35],
      className: "Restaurant" + geoJsonPoint.properties.id,
    });
    return L.marker(latlng, { icon: icon });
  };

  const dataFilter = (
    geoJsonData: any,
    inputShopName: string,
    typeName: string
  ) => {
    let filteredDataByType;
    if (typeName === "") {
      filteredDataByType = geoJsonData;
    } else {
      // filteredDataByType = geoJsonData.features.filter(
      //   (point: any) => point.properties.type === typeName
      // );
    }
    if (inputShopName === "") {
      return filteredDataByType;
    } else {
      return filteredDataByType.features.filter(
        (point: any) =>
          point.properties.shopName
            .toLowerCase()
            .indexOf(inputShopName.toLowerCase()) > -1
      );
    }
  };
  return (
    <>
      {serchedShopName !== undefined &&
        serchedShopName.length % 2 === 0 &&
        restaurantGeoJsonData !== undefined && (
          <GeoJSON
            data={dataFilter(
              restaurantGeoJsonData,
              serchedShopName,
              serchedType
            )}
            onEachFeature={onEachPoint}
            pointToLayer={markerOptions}
          />
        )}
      {serchedShopName !== undefined &&
        serchedShopName.length % 2 !== 0 &&
        restaurantGeoJsonData !== undefined && (
          <GeoJSON
            data={dataFilter(
              restaurantGeoJsonData,
              serchedShopName,
              serchedType
            )}
            onEachFeature={onEachPoint}
            pointToLayer={markerOptions}
          />
        )}
      {isModalShow && (
        <Modal
          show={isModalShow}
          onHide={handleCloseModal}
          centered
          size="lg"
          className="pin-detail-modal"
        >
          <Modal.Body>
            {" "}
            <Carousel variant={"danger"}>
              {modalImage.map((item, idx) => (
                <Carousel.Item>
                  <Image
                    width={"100%"}
                    src={`/images/Restaurant/${item}.jpg`}
                    alt={"COMING SOON"}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
          <Modal.Footer>
            <Button size={"sm"} variant="primary" onClick={handleCloseModal}>
              閉じる
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default Restaurant;
