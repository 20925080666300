import L, { Layer } from "leaflet";
import { GeoJSON } from "react-leaflet";
import { ReactElement, useEffect, useState } from "react";
import {
  Button,
  Card,
  Carousel,
  Col,
  Container,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import * as ReactDOMServer from "react-dom/server";
import ktvGeoJsonData from "../geoJson/ktv.json";
import ktvPin from "../icons/KTVPin.png";

type KTVProps = {
  serchedShopName: string;
  langSetting: string;
};

const KTV: React.FC<KTVProps> = ({ serchedShopName, langSetting }) => {
  const [isModalShow, setIsMondalShow] = useState(false);
  const [modalImage, setModalImage] = useState<[string]>([""]);
  const handleCloseModal = () => setIsMondalShow(false);
  const onEachPoint = (point: any, layer: Layer) => {
    const element: ReactElement = (
      <Card style={{ width: "100%", border: "none" }} bg={"dark"} text="light">
        <Card.Img
          id={`KTV${point.properties.id}`}
          variant="top"
          src={`/images/KTV/${point.properties.imageName}.jpg`}
          alt={"COMING SOON"}
        />
        <Card.Body className="p-2">
          {/* <Card.Subtitle className="mt-0  mb-2">【KTV】</Card.Subtitle> */}
          <Container>
            <Row>
              <Col xs={6}>{langSetting === "JA" ? "店舗名" : "ShopName"}</Col>
              <Col xs={6}>
                {point.properties.shopName.split("|||")[0]}
                <span className="tag-for-search">
                  {point.properties.shopName.split("|||")[1]}
                </span>
              </Col>
            </Row>
            {point.properties.setPrice60 && (
              <Row>
                <Col xs={6}>
                  {langSetting === "JA" ? "60分セット料金" : "60 minute price"}
                </Col>
                <Col xs={6}>{point.properties.setPrice60} PHP</Col>
              </Row>
            )}
            {point.properties.setPrice90 && (
              <Row>
                <Col xs={6}>
                  {langSetting === "JA" ? "90分セット料金" : "90 minute price"}
                </Col>
                <Col xs={6}>{point.properties.setPrice90} PHP</Col>
              </Row>
            )}
            {point.properties.setPrice120 && (
              <Row>
                <Col xs={6}>
                  {langSetting === "JA"
                    ? "120分セット料金"
                    : "120 minute price"}
                </Col>
                <Col xs={6}>{point.properties.setPrice120} PHP</Col>
              </Row>
            )}
            {point.properties.vipRoom90 && (
              <Row>
                <Col xs={6}>
                  {langSetting === "JA"
                    ? "90分VIP料金"
                    : "90 minutes VIP price"}
                </Col>
                <Col xs={6}>{point.properties.vipRoom90} PHP</Col>
              </Row>
            )}
            {point.properties.vipRoom120 && (
              <Row>
                <Col xs={6}>
                  {langSetting === "JA"
                    ? "120分VIP料金"
                    : "120 minutes VIP price"}
                </Col>
                <Col xs={6}>{point.properties.vipRoom120} PHP</Col>
              </Row>
            )}
            {point.properties.ladiesRequest && (
              <Row>
                <Col xs={6}>
                  {langSetting === "JA" ? "指名料" : "Nomination fee"}
                </Col>
                <Col xs={6}>{point.properties.ladiesRequest} PHP</Col>
              </Row>
            )}
            {point.properties.ladiesDrink && (
              <Row>
                <Col xs={6}>
                  {langSetting === "JA" ? "女の子ドリンク" : "Ladys drinks"}
                </Col>
                <Col xs={6}>{point.properties.ladiesDrink} PHP</Col>
              </Row>
            )}
            {point.properties.extention60 && (
              <Row>
                <Col xs={6}>
                  {langSetting === "JA" ? "延長料金" : "Extension fee"}
                </Col>
                <Col xs={6}>{point.properties.extention60} PHP</Col>
              </Row>
            )}
            {point.properties.vipExtention60 && (
              <Row>
                <Col xs={6}>
                  {langSetting === "JA" ? "VIP 延長料金" : "VIP "}
                </Col>
                <Col xs={6}>{point.properties.vipExtention60} PHP</Col>
              </Row>
            )}
            {point.properties.HP && (
              <Row>
                <Col xs={6}>HP</Col>
                <Col xs={6}>
                  <a
                    href={point.properties.HP}
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    {point.properties.HP}
                  </a>
                </Col>
              </Row>
            )}
            {point.properties.memo && (
              <Row>
                <Col xs={6}>{langSetting === "JA" ? "備考" : "Memo"}</Col>
                <Col xs={6}>{point.properties.memo}</Col>
              </Row>
            )}
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-center">
          {" "}
          {point.properties.googleUrl && (
            <Button
              variant="outline-light"
              size="sm"
              href={
                navigator.userAgent.match(/iPhone/)
                  ? point.properties.googleLink
                  : point.properties.googleUrl
              }
              target={"_blank"}
              rel={"noreferrer"}
              color={"white!important"}
            >
              {" "}
              {langSetting === "JA" ? "ここへ行く" : "Go here"}
            </Button>
          )}
        </Card.Footer>
      </Card>
    );
    layer
      .bindPopup(`${ReactDOMServer.renderToString(element)}`, {
        maxHeight: 450,
        maxWidth: 350,
        minWidth: 270,
      })
      .on("popupopen", () => {
        document
          .getElementById(`KTV${point.properties.id}`)
          ?.addEventListener("click", () => {
            setIsMondalShow(true);
            point.properties.otherImages
              ? setModalImage(point.properties.otherImages.split(","))
              : setModalImage([point.properties.imageName]);
          });
      });
  };
  const markerOptions = (geoJsonPoint: any, latlng: L.LatLng) => {
    let icon = L.icon({
      iconUrl: ktvPin,
      iconSize: [35, 53],
      iconAnchor: [17.5, 53],
      popupAnchor: [0, -35],
      className: "KTV" + geoJsonPoint.properties.id,
    });
    return L.marker(latlng, { icon: icon });
  };

  const dataFilter = (geoJsonData: any, inputShopName: string) => {
    if (inputShopName === "") {
      return geoJsonData;
    } else {
      return geoJsonData.features.filter(
        (point: any) =>
          point.properties.shopName
            .toLowerCase()
            .indexOf(inputShopName.toLowerCase()) > -1
      );
    }
  };

  return (
    <>
      {serchedShopName !== undefined &&
        serchedShopName.length % 2 === 0 &&
        ktvGeoJsonData !== undefined && (
          <GeoJSON
            data={dataFilter(ktvGeoJsonData, serchedShopName)}
            onEachFeature={onEachPoint}
            pointToLayer={markerOptions}
          />
        )}
      {serchedShopName !== undefined &&
        serchedShopName.length % 2 !== 0 &&
        ktvGeoJsonData !== undefined && (
          <GeoJSON
            data={dataFilter(ktvGeoJsonData, serchedShopName)}
            onEachFeature={onEachPoint}
            pointToLayer={markerOptions}
          />
        )}
      {isModalShow && (
        <Modal
          show={isModalShow}
          onHide={handleCloseModal}
          centered
          size="lg"
          className="pin-detail-modal"
        >
          <Modal.Body>
            {" "}
            <Carousel variant={"danger"}>
              {modalImage.map((item, idx) => (
                <Carousel.Item>
                  <Image
                    width={"100%"}
                    src={`/images/KTV/${item}.jpg`}
                    alt={"COMING SOON"}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
          <Modal.Footer>
            <Button size={"sm"} variant="primary" onClick={handleCloseModal}>
              閉じる
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default KTV;
