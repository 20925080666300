import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Stack from "react-bootstrap/Stack";
import { FooterBtnState, LanguageSetting } from "../common/Const";
import SportsBarOutlinedIcon from "@mui/icons-material/SportsBarOutlined";
import RestaurantOutlinedIcon from "@mui/icons-material/RestaurantOutlined";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined";
import CasinoOutlinedIcon from "@mui/icons-material/CasinoOutlined";
import LocalConvenienceStoreOutlinedIcon from "@mui/icons-material/LocalConvenienceStoreOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";

import styled from "styled-components";

type BtnFooterProps = {
  footerBtnState: FooterBtnState;
  setSerchedShopName: (serchedShopName: string) => void;
  setFooterBtnState: (footerBtnState: FooterBtnState) => void;
  langSetting: LanguageSetting;
  setZoomOfMap: (magnification: number) => void;
  setIsMoveCenter: (isMoveCenter: boolean) => void;
};

const BtnFooter: React.FC<BtnFooterProps> = ({
  footerBtnState,
  setSerchedShopName,
  setFooterBtnState,
  langSetting,
  setZoomOfMap,
  setIsMoveCenter,
}) => {
  // const initialZoom: number = 17;
  return (
    <>
      <StyledNavbar bg="dark" fixed="bottom">
        <StyledFooterBtnContainer className={"footer-btn-container"}>
          <StyledNav fill justify variant="tabs">
            <StyledNavItem>
              <StyledNavLink
                onClick={() => {
                  setSerchedShopName("");
                  setFooterBtnState("KTV");

                  // setZoomOfMap(initialZoom);
                  // setIsMoveCenter(true);
                }}
              >
                <Badge
                  id="ktv-footer-btn"
                  bg={footerBtnState === "KTV" ? "primary" : ""}
                >
                  <Stack gap={1} className="d-flex align-items-center">
                    <SportsBarOutlinedIcon
                      fontSize="large"
                      sx={{ color: "white" }}
                    />
                    {langSetting === "JA" ? "KTV" : "KTV"}
                  </Stack>
                </Badge>
              </StyledNavLink>
            </StyledNavItem>
            <StyledNavItem>
              <StyledNavLink
                onClick={() => {
                  setSerchedShopName("");
                  setFooterBtnState("Restaurant");
                  // setZoomOfMap(initialZoom);
                  // setIsMoveCenter(true);
                }}
              >
                <Badge
                  id="restaurant-footer-btn"
                  bg={footerBtnState === "Restaurant" ? "primary" : ""}
                >
                  <Stack gap={1} className="d-flex align-items-center">
                    <RestaurantOutlinedIcon fontSize="large" />
                    {langSetting === "JA" ? "レストラン" : "Restaurant"}
                  </Stack>
                </Badge>
              </StyledNavLink>
            </StyledNavItem>
            <StyledNavItem>
              <StyledNavLink
                onClick={() => {
                  setSerchedShopName("");
                  setFooterBtnState("Hotel");
                  // setZoomOfMap(initialZoom);
                  // setIsMoveCenter(true);
                }}
              >
                <Badge
                  id="restaurant-footer-btn"
                  bg={footerBtnState === "Hotel" ? "primary" : ""}
                >
                  <Stack gap={1} className="d-flex align-items-center">
                    <HotelOutlinedIcon fontSize="large" />
                    {langSetting === "JA" ? "ホテル" : "Hotel"}
                  </Stack>
                </Badge>
              </StyledNavLink>
            </StyledNavItem>
            <StyledNavItem>
              <StyledNavLink
                onClick={() => {
                  setSerchedShopName("");
                  setFooterBtnState("PokerRoom");
                  // setZoomOfMap(initialZoom);
                  // setIsMoveCenter(true);
                }}
              >
                <Badge
                  id="poker-room-footer-btn"
                  bg={footerBtnState === "PokerRoom" ? "primary" : ""}
                >
                  <Stack gap={1} className="d-flex align-items-center">
                    <StyleOutlinedIcon fontSize="large" />
                    {langSetting === "JA" ? "ポーカー" : "PokerRoom"}
                  </Stack>
                </Badge>
              </StyledNavLink>
            </StyledNavItem>
            <StyledNavItem>
              <StyledNavLink
                onClick={() => {
                  setSerchedShopName("");
                  setFooterBtnState("Casino");
                  // setZoomOfMap(initialZoom);
                  // setIsMoveCenter(true);
                }}
              >
                <Badge
                  id="casino-footer-btn"
                  bg={footerBtnState === "Casino" ? "primary" : ""}
                >
                  <Stack gap={1} className="d-flex align-items-center">
                    <CasinoOutlinedIcon fontSize="large" />
                    {langSetting === "JA" ? "カジノ" : "Casino"}
                  </Stack>
                </Badge>
              </StyledNavLink>
            </StyledNavItem>
            <StyledNavItem>
              <StyledNavLink
                onClick={() => {
                  setFooterBtnState("Conveni");
                  // setZoomOfMap(initialZoom);
                  // setIsMoveCenter(true);
                }}
              >
                <Badge
                  id="conveni-footer-btn"
                  bg={footerBtnState === "Conveni" ? "primary" : ""}
                >
                  <Stack gap={1} className="d-flex align-items-center">
                    <LocalConvenienceStoreOutlinedIcon fontSize="large" />
                    {langSetting === "JA" ? "コンビニ" : "CVS"}
                  </Stack>
                </Badge>
              </StyledNavLink>
            </StyledNavItem>
            <StyledNavItem>
              <StyledNavLink
                onClick={() => {
                  setFooterBtnState("MoneyExchanger");
                  // setZoomOfMap(initialZoom);
                  // setIsMoveCenter(true);
                }}
              >
                <Badge
                  id="money-exchanger-footer-btn"
                  bg={footerBtnState === "MoneyExchanger" ? "primary" : ""}
                >
                  <Stack gap={1} className="d-flex align-items-center">
                    <CurrencyExchangeOutlinedIcon fontSize="large" />
                    {langSetting === "JA" ? "両替所" : "Exchanger"}
                  </Stack>
                </Badge>
              </StyledNavLink>
            </StyledNavItem>
          </StyledNav>
        </StyledFooterBtnContainer>
      </StyledNavbar>
    </>
  );
};
export default BtnFooter;

const StyledNavbar = styled(Navbar)`
  height: 60px;
  z-index: 1300;
  padding: 0px;
  .nav-tabs {
    border-style: none;
    height: 100%;
  }
  /* PWA環境下でのみ実行する設定を記述 */
  @media (display-mode: standalone) {
    height: 85px;
  }
`;
const StyledFooterBtnContainer = styled(Container)`
  overflow: scroll;
  padding: 0;
  height: 100%;
`;
const StyledNav = styled(Nav)``;
const StyledNavItem = styled(Nav.Item)`
  .nav-link {
    border-style: none;
    padding: 0 !important;
  }
  .badge {
    width: 70px;
  }
`;
const StyledNavLink = styled(Nav.Link)``;
