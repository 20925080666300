import L, { Layer } from "leaflet";
import { GeoJSON } from "react-leaflet";
import { ReactElement, useState } from "react";
import {
  Button,
  Card,
  Carousel,
  Col,
  Container,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import * as ReactDOMServer from "react-dom/server";
import casinoGeoJsonData from "../geoJson/casino.json";
import casinoPin from "../icons/casinoPin.png";

type CasinoProps = {
  serchedShopName: string;
  langSetting: string;
};

const Casino: React.FC<CasinoProps> = ({ serchedShopName, langSetting }) => {
  const [isModalShow, setIsMondalShow] = useState(false);
  const [modalImage, setModalImage] = useState<[string]>([""]);
  const [howToPorkerRoomOffCanvasShow, setHowToPorkerRoomOffCanvasShow] =
    useState(false);

  const handleCloseModal = () => setIsMondalShow(false);
  const handleHowToPorkerRoomOffCanvasClose = () => {
    setHowToPorkerRoomOffCanvasShow(false);
  };
  const handleHowToPorkerRoomOffCanvasShow = () =>
    setHowToPorkerRoomOffCanvasShow(true);

  const onEachPoint = (point: any, layer: Layer) => {
    const element: ReactElement = (
      <Card style={{ width: "100%", border: "none" }} bg={"dark"} text="light">
        <Card.Img
          id={`Casino${point.properties.id}`}
          variant="top"
          src={`/images/Casino/${point.properties.imageName}.jpg`}
          alt={"COMING SOON"}
        />
        <Card.Body className="p-2">
          {/* <Card.Subtitle className="mt-0  mb-2">【カジノ】</Card.Subtitle> */}
          <Container>
            <Row>
              <Col xs={6}> {langSetting === "JA" ? "店舗名" : "ShopName"}</Col>
              <Col xs={6}>
                {point.properties.shopName.split("|||")[0]}
                <span className="tag-for-search">
                  {point.properties.shopName.split("|||")[1]}
                </span>
              </Col>
            </Row>
            {point.properties.memo && (
              <Row>
                <Col xs={6}> {langSetting === "JA" ? "備考" : "Memo"}</Col>
                <Col xs={6}>{point.properties.memo}</Col>
              </Row>
            )}
          </Container>
        </Card.Body>
      </Card>
    );
    layer
      .bindPopup(`${ReactDOMServer.renderToString(element)}`, {
        maxHeight: 450,
        maxWidth: 350,
        minWidth: 270,
      })
      .on("popupopen", () => {
        document
          .getElementById(`Casino${point.properties.id}`)
          ?.addEventListener("click", () => {
            setIsMondalShow(true);
            point.properties.otherImages
              ? setModalImage(point.properties.otherImages.split(","))
              : setModalImage([point.properties.imageName]);
          });
      });
  };
  const markerOptions = (geoJsonPoint: any, latlng: L.LatLng) => {
    let icon = L.icon({
      iconUrl: casinoPin,
      iconSize: [35, 53],
      iconAnchor: [17.5, 53],
      popupAnchor: [0, -35],
      className: "Casino" + geoJsonPoint.properties.id,
    });
    return L.marker(latlng, { icon: icon });
  };

  const dataFilter = (geoJsonData: any, inputShopName: string) => {
    if (inputShopName === "") {
      return geoJsonData;
    } else {
      return geoJsonData.features.filter(
        (point: any) =>
          point.properties.shopName
            .toLowerCase()
            .indexOf(inputShopName.toLowerCase()) > -1
      );
    }
  };

  return (
    <>
      {serchedShopName !== undefined &&
        serchedShopName.length % 2 === 0 &&
        casinoGeoJsonData !== undefined && (
          <GeoJSON
            data={dataFilter(casinoGeoJsonData, serchedShopName)}
            onEachFeature={onEachPoint}
            pointToLayer={markerOptions}
          />
        )}
      {serchedShopName !== undefined &&
        serchedShopName.length % 2 !== 0 &&
        casinoGeoJsonData !== undefined && (
          <GeoJSON
            data={dataFilter(casinoGeoJsonData, serchedShopName)}
            onEachFeature={onEachPoint}
            pointToLayer={markerOptions}
          />
        )}
      {isModalShow && (
        <Modal
          show={isModalShow}
          onHide={handleCloseModal}
          centered
          size="lg"
          className="pin-detail-modal"
        >
          <Modal.Body>
            {" "}
            <Carousel variant={"danger"}>
              {modalImage.map((item, idx) => (
                <Carousel.Item>
                  <Image
                    width={"100%"}
                    src={`/images/Casino/${item}.jpg`}
                    alt={"COMING SOON"}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
          <Modal.Footer>
            <Button size={"sm"} variant="primary" onClick={handleCloseModal}>
              閉じる
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* <Offcanvas
        show={howToPorkerRoomOffCanvasShow}
        onHide={handleHowToPorkerRoomOffCanvasClose}
        scroll={true}
        backdrop={false}
        placement="bottom"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title as={"p"}>マニラカジノの歩き方</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body></Offcanvas.Body>
      </Offcanvas> */}
    </>
  );
};
export default Casino;
