import { Image, Navbar, Stack } from "react-bootstrap";
import LangToggleSwitch from "./LangToggleSwitch";
import { LanguageSetting } from "../common/Const";
import styled from "styled-components";

type HeaderNavProps = {
  langSetting: LanguageSetting;
  setIsQRModalShow: (isQRCodeShow: boolean) => void;
  handleLangChange: () => void;
};
const HeaderNav: React.FC<HeaderNavProps> = ({
  langSetting,
  setIsQRModalShow,
  handleLangChange,
}) => {
  return (
    <>
      <StyledNavBar bg="dark" className="pt-0 pb-1 justify-content-between">
        <Navbar.Brand className="nav-brand text-light pb-1 pt-0 pb-0 ms-3 ">
          <a className="topTitle" href="https://manila-map.info/">
            <Image
              alt=""
              src="/fhilippinesFlag.svg"
              width="30"
              height="20"
              className="d-inline-block align-center me-2 mb-1"
            />{" "}
            {langSetting === "JA" ? "マニラMap" : "Manila Map"}
          </a>
        </Navbar.Brand>
        <Navbar.Brand className=" pt-2 pb-1 me-3">
          <Stack direction="horizontal" gap={3}>
            <Image
              alt=""
              src="manilaMapQR.png"
              width="40"
              height="40"
              className="d-inline-block align-center pe-0 mb-0 p-0"
              onClick={() => setIsQRModalShow(true)}
            />
            <LangToggleSwitch
              handleLangChange={handleLangChange}
            ></LangToggleSwitch>
          </Stack>
        </Navbar.Brand>
      </StyledNavBar>
    </>
  );
};
export default HeaderNav;

const StyledNavBar = styled(Navbar)`
  height: 55px;

  .topTitle,
  topTitle:hover,
  topTitle:visited {
    color: inherit;
    text-decoration: none;
  }
`;
