import React from "react";
import styled from "styled-components";

type LangToggleSwitchProps = {
  handleLangChange: () => void;
};

const LangToggleSwitch: React.FC<LangToggleSwitchProps> = ({
  handleLangChange,
}) => {
  return (
    <>
      <StyledLangToggleSwitch>
        <input id="btn-mode" type="checkbox" onChange={handleLangChange} />
        <label htmlFor="btn-mode"></label>
      </StyledLangToggleSwitch>
    </>
  );
};

const StyledLangToggleSwitch = styled.div`
  & input {
    display: none;
    &:checked + label {
      background-color: #0095d9;
      &::before {
        left: 1em;
        content: "EN";
      }
    }
  }

  & label {
    background-color: #ff9933;
    border-radius: 2em;
    border: 2px solid var(--text-color);
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 1.8em;
    position: relative;
    transition: 0.5s;
    width: 2.75em;

    &::before {
      background: #fff;
      border-radius: 100%;
      content: "JA";
      display: inline-block;
      height: 1.5em;
      position: absolute;
      left: 0.25em;
      transition: 0.3s ease-out;
      width: 1.5em;
      z-index: 2;
    }
  }
`;

export default LangToggleSwitch;
