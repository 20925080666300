import L, { Layer } from "leaflet";
import { GeoJSON } from "react-leaflet";
import { ReactElement } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import * as ReactDOMServer from "react-dom/server";
import conveniGeoJsonData from "../geoJson/conveni.json";
import conveniPin from "../icons/conveniPin.png";

type ConviniProps = { langSetting: string };

const Conveni: React.FC<ConviniProps> = ({ langSetting }) => {
  const onEachPoint = (point: any, layer: Layer) => {
    const element: ReactElement = (
      <Card style={{ width: "100%", border: "none" }} bg={"dark"} text="light">
        <Card.Img
          variant="top"
          src={`/images/Conveni/${point.properties.imageName}.jpg`}
          alt={"COMING SOON"}
        />
        <Card.Body className="p-2">
          {/* <Card.Subtitle className="mt-0  mb-2">【コンビニ】</Card.Subtitle> */}
          <Container>
            <Row>
              <Col xs={6}>{langSetting === "JA" ? "店舗名" : "ShopName"}</Col>
              <Col xs={6}>{point.properties.shopName}</Col>
            </Row>
            {point.properties.tag && (
              <Row>
                <Col xs={6}>{langSetting === "JA" ? "タグ" : "Tag"}</Col>
                <Col xs={6}>{point.properties.tag}</Col>
              </Row>
            )}
            {point.properties.memo && (
              <Row>
                <Col xs={6}>{langSetting === "JA" ? "備考" : "Memo"}</Col>
                <Col xs={6}>{point.properties.memo}</Col>
              </Row>
            )}
          </Container>
        </Card.Body>
      </Card>
    );
    layer.bindPopup(`${ReactDOMServer.renderToString(element)}`, {
      maxHeight: 450,
      maxWidth: 350,
      minWidth: 270,
    });
  };
  const markerOptions = (geoJsonPoint: any, latlng: L.LatLng) => {
    let icon = L.icon({
      iconUrl: conveniPin,
      iconSize: [35, 53],
      iconAnchor: [17.5, 53],
      popupAnchor: [0, -35],
    });
    return L.marker(latlng, { icon: icon });
  };

  return (
    <>
      {conveniGeoJsonData !== undefined && (
        <GeoJSON
          data={JSON.parse(JSON.stringify(conveniGeoJsonData))}
          onEachFeature={onEachPoint}
          pointToLayer={markerOptions}
        />
      )}
    </>
  );
};
export default Conveni;
