import L, { Layer } from "leaflet";
import { GeoJSON } from "react-leaflet";
import { ReactElement, useState } from "react";
import {
  Button,
  Card,
  Carousel,
  Col,
  Container,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import * as ReactDOMServer from "react-dom/server";
import hotelGeoJsonData from "../geoJson/hotel.json";
import hotelPin from "../icons/hotelPin.png";

type HotelProps = {
  serchedShopName: string;
  langSetting: string;
};

const Hotel: React.FC<HotelProps> = ({ serchedShopName, langSetting }) => {
  const [isModalShow, setIsMondalShow] = useState(false);
  const [modalImage, setModalImage] = useState<[string]>([""]);
  const handleCloseModal = () => setIsMondalShow(false);
  const onEachPoint = (point: any, layer: Layer) => {
    const element: ReactElement = (
      <Card style={{ width: "100%", border: "none" }} bg={"dark"} text="light">
        <Card.Img
          id={`Hotel${point.properties.id}`}
          variant="top"
          src={`/images/Hotel/${point.properties.imageName}.jpg`}
          // src={`${point.properties.imageName}`}
          alt={"COMING SOON"}
        />
        <Card.Body className="p-2">
          <Container>
            <Row>
              <Col xs={4}>
                {langSetting === "JA" ? "ホテル名" : "Hotel Name"}
              </Col>
              <Col xs={8}>{point.properties.shopName}</Col>
            </Row>
            {point.properties.memo && (
              <Row>
                <Col xs={4}>{langSetting === "JA" ? "備考" : "Memo"}</Col>
                <Col xs={8}>{point.properties.memo}</Col>
              </Row>
            )}
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-center">
          <Container>
            <Row className="mb-2">
              <Button
                variant="outline-primary"
                size="sm"
                href={point.properties.tripcomUrl}
                target={"_blank"}
                rel={"noreferrer"}
                color={"blue!important"}
              >
                {" "}
                {langSetting === "JA" ? "詳細" : "Click me"}
              </Button>
            </Row>
            {point.properties.googleUrl && (
              <Row>
                <Button
                  variant="outline-light"
                  size="sm"
                  href={
                    navigator.userAgent.match(/iPhone/)
                      ? point.properties.googleLink
                      : point.properties.googleUrl
                  }
                  target={"_blank"}
                  rel={"noreferrer"}
                  color={"white!important"}
                >
                  {" "}
                  {langSetting === "JA" ? "ここへ行く" : "Go here"}
                </Button>
              </Row>
            )}
          </Container>
        </Card.Footer>
      </Card>
    );
    layer
      .bindPopup(`${ReactDOMServer.renderToString(element)}`, {
        maxHeight: 450,
        maxWidth: 300,
        minWidth: 270,
      })
      .on("popupopen", () => {
        document
          .getElementById(`Hotel${point.properties.id}`)
          ?.addEventListener("click", () => {
            setIsMondalShow(true);
            point.properties.otherImages
              ? setModalImage(point.properties.otherImages.split(","))
              : setModalImage([point.properties.imageName]);
          });
      });
  };
  const markerOptions = (geoJsonPoint: any, latlng: L.LatLng) => {
    let icon = L.icon({
      iconUrl: hotelPin,
      iconSize: [35, 53],
      iconAnchor: [17.5, 53],
      popupAnchor: [0, -35],
      className: "Hotel" + geoJsonPoint.properties.id,
    });
    return L.marker(latlng, { icon: icon });
  };

  const dataFilter = (geoJsonData: any, inputShopName: string) => {
    if (inputShopName === "") {
      return geoJsonData;
    } else {
      return geoJsonData.features.filter(
        (point: any) =>
          point.properties.name
            .toLowerCase()
            .indexOf(inputShopName.toLowerCase()) > -1
      );
    }
  };

  return (
    <>
      {serchedShopName !== undefined &&
        serchedShopName.length % 2 === 0 &&
        hotelGeoJsonData !== undefined && (
          <GeoJSON
            data={dataFilter(hotelGeoJsonData, serchedShopName)}
            onEachFeature={onEachPoint}
            pointToLayer={markerOptions}
          />
        )}
      {serchedShopName !== undefined &&
        serchedShopName.length % 2 !== 0 &&
        hotelGeoJsonData !== undefined && (
          <GeoJSON
            data={dataFilter(hotelGeoJsonData, serchedShopName)}
            onEachFeature={onEachPoint}
            pointToLayer={markerOptions}
          />
        )}
      {isModalShow && (
        <Modal
          show={isModalShow}
          onHide={handleCloseModal}
          centered
          size="lg"
          className="pin-detail-modal"
        >
          <Modal.Body>
            {" "}
            <Carousel variant={"danger"}>
              {modalImage.map((item, idx) => (
                <Carousel.Item>
                  <Image width={"100%"} src={`${item}`} alt={"COMING SOON"} />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
          <Modal.Footer>
            <Button size={"sm"} variant="primary" onClick={handleCloseModal}>
              閉じる
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default Hotel;
