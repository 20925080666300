import L, { Layer } from "leaflet";
import { GeoJSON } from "react-leaflet";
import { ReactElement, useState } from "react";
import {
  Button,
  Card,
  Carousel,
  Col,
  Container,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import * as ReactDOMServer from "react-dom/server";
import moneyExchangerGeoJsonData from "../geoJson/moneyExchanger.json";
import moneyExchangerPin from "../icons/moneyExchangerPin.png";

type MoneyExchangerProps = { langSetting: string };

const MoneyExchanger: React.FC<MoneyExchangerProps> = ({ langSetting }) => {
  const [isModalShow, setIsMondalShow] = useState(false);
  const [modalImage, setModalImage] = useState<[string]>([""]);
  const handleCloseModal = () => setIsMondalShow(false);

  const onEachPoint = (point: any, layer: Layer) => {
    const element: ReactElement = (
      <Card style={{ width: "100%", border: "none" }} bg={"dark"} text="light">
        <Card.Img
          id={`moneyEx${point.properties.id}`}
          variant="top"
          src={`/images/MoneyExchanger/${point.properties.imageName}.jpg`}
          alt={"COMING SOON"}
        />
        <Card.Body className="p-2">
          {/* <Card.Subtitle className="mt-0  mb-2">【両替所】</Card.Subtitle> */}
          <Container className="mb-0">
            <Row>
              <Col xs={6}>{langSetting === "JA" ? "店舗名" : "ShopName"}</Col>
              <Col xs={6}>{point.properties.shopName}</Col>
            </Row>
            {point.properties.tag && (
              <Row>
                <Col xs={6}>{langSetting === "JA" ? "タグ" : "Tag"}</Col>
                <Col xs={6}>{point.properties.tag}</Col>
              </Row>
            )}
            {point.properties.memo && (
              <Row>
                <Col xs={6}>{langSetting === "JA" ? "メモ" : "Memo"}</Col>
                <Col xs={6}>{point.properties.memo}</Col>
              </Row>
            )}
          </Container>
        </Card.Body>
      </Card>
    );
    layer
      .bindPopup(`${ReactDOMServer.renderToString(element)}`, {
        maxHeight: 450,
        maxWidth: 350,
        minWidth: 270,
      })
      .on("popupopen", () => {
        document
          .getElementById(`moneyEx${point.properties.id}`)
          ?.addEventListener("click", () => {
            setIsMondalShow(true);

            point.properties.otherImages
              ? setModalImage(point.properties.otherImages.split(","))
              : setModalImage([point.properties.imageName]);
          });
      });
  };

  const markerOptions = (geoJsonPoint: any, latlng: L.LatLng) => {
    let icon = L.icon({
      iconUrl: moneyExchangerPin,
      iconSize: [35, 53],
      iconAnchor: [17.5, 53],
      popupAnchor: [0, -35],
      className: "MoneyExchanger" + geoJsonPoint.properties.id,
    });
    return L.marker(latlng, { icon: icon });
  };

  return (
    <>
      {moneyExchangerGeoJsonData !== undefined && (
        <GeoJSON
          data={JSON.parse(JSON.stringify(moneyExchangerGeoJsonData))}
          onEachFeature={onEachPoint}
          pointToLayer={markerOptions}
        />
      )}
      {isModalShow && (
        <Modal
          show={isModalShow}
          onHide={handleCloseModal}
          centered
          size="lg"
          className="pin-detail-modal"
        >
          <Modal.Body>
            {" "}
            <Carousel variant={"danger"}>
              {modalImage.map((item, idx) => (
                <Carousel.Item>
                  <Image
                    width={"100%"}
                    src={`/images/MoneyExchanger/${item}.jpg`}
                    alt={"COMING SOON"}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
          <Modal.Footer>
            <Button size={"sm"} variant="primary" onClick={handleCloseModal}>
              閉じる
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default MoneyExchanger;
