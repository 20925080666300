import { Button, Image, Modal } from "react-bootstrap";
import { LanguageSetting } from "../common/Const";
import React from "react";

type QRCodeModalProps = {
  isQRModalShow: boolean;
  setIsQRModalShow: (isQRModalShow: boolean) => void;
  langSetting: LanguageSetting;
};

const QRCodeModal: React.FC<QRCodeModalProps> = ({
  isQRModalShow,
  setIsQRModalShow,
  langSetting,
}) => {
  return (
    <Modal
      show={isQRModalShow}
      onHide={() => setIsQRModalShow(false)}
      centered
      size="lg"
      className="pin-detail-modal"
    >
      <Modal.Body>
        <Image
          alt=""
          src="manilaMapQR.png"
          width="100%"
          height="100%"
          className="d-inline-block align-center pe-0 mb-0 p-0"
          fluid
        />
      </Modal.Body>

      <Modal.Footer className="pt-0 d-flex justify-content-between">
        <Button
          size={"sm"}
          variant="primary"
          onClick={() => setIsQRModalShow(false)}
        >
          {" "}
          {langSetting === "JA" ? "閉じる" : "Close"}
        </Button>
        <h6 className="text-light ps-3 pt-2">
          {langSetting === "JA"
            ? "お問い合わせ先 : info@manila-map.info"
            : `For Free Advertising : info@manila-map.info`}
        </h6>
      </Modal.Footer>
    </Modal>
  );
};
export default QRCodeModal;
