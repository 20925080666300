import { useEffect } from "react";

export const useLockScreenOrientation = (orientation = "landscape-primary") => {
  useEffect(() => {
    const screenOrientation = window.screen.orientation as any;
    if (screenOrientation.lock) {
      screenOrientation.lock(orientation).catch((err: Error) => {
        console.warn("Screen orientation lock failed:", err);
      });
    }
  }, [orientation]);
};
