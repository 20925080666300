import "bootstrap/dist/css/bootstrap.min.css";
import "leaflet/dist/leaflet.css";
import "./App.css";
import Top from "./components/Top";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

const App: React.FC = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 900);
  });
  const touchHandler = (event: any) => {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  };
  document.addEventListener("touchstart", touchHandler, {
    passive: false,
  });

  return (
    <>
      {isLoading ? (
        <div className="loading">
          <Spinner animation="grow" variant="primary" />
        </div>
      ) : (
        <Top />
      )}
    </>
  );
};

export default App;
