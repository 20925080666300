import * as React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import active_KTVPin from "../icons/active_KTVPin.png";
import { FooterBtnState } from "../common/Const";
import ktvGeoJsonData from "../geoJson/ktv.json";
import restaurantGeoJsonData from "../geoJson/restaurant.json";
import hotelGeoJsonData from "../geoJson/hotel.json";
import porkerGeoJsonData from "../geoJson/pokerRoom.json";
import conveniGeoJsonData from "../geoJson/conveni.json";
import casinoGeoJsonData from "../geoJson/casino.json";
import moneyExchangerGeoJsonData from "../geoJson/moneyExchanger.json";
import { Container, Row, Button } from "react-bootstrap";
import ImageWithPlaceholder from "./ImageWithPlaceholder";

let drawerBleeding: number;
window.matchMedia("(display-mode: standalone)").matches
  ? (drawerBleeding = 120)
  : (drawerBleeding = 100);

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  // backgroundColor: grey[100],
  backgroundColor: "rgb(53, 57, 61)",
}));

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: "rgb(53, 57, 61)",
}));

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

type SwipeableEdgeDrawerProps = {
  footerBtnState: FooterBtnState;
  setCenterOfMap: (position: L.LatLngTuple) => void;
  swipeableEdgeDrawerOpen: boolean;
  setSwipeableEdgeDrawerOpen: (isOpen: boolean) => void;
  setIsMoveCenter: (isMove: boolean) => void;
  setPinId: (id: string) => void;
  setZoomOfMap: (magnification: number) => void;
};

const SwipeableEdgeDrawer: React.FC<SwipeableEdgeDrawerProps> = ({
  footerBtnState,
  setCenterOfMap,
  swipeableEdgeDrawerOpen,
  setSwipeableEdgeDrawerOpen,
  setIsMoveCenter,
  setPinId,
  setZoomOfMap,
}) => {
  const toggleDrawer = (newOpen: boolean) => () => {
    setSwipeableEdgeDrawerOpen(newOpen);
  };
  let geoJsonFeatures;
  let name = "";

  switch (footerBtnState) {
    case "KTV":
      geoJsonFeatures = ktvGeoJsonData.features;
      name = ktvGeoJsonData.name;

      break;
    case "Restaurant":
      geoJsonFeatures = restaurantGeoJsonData.features;
      name = restaurantGeoJsonData.name;
      break;
    case "Hotel":
      geoJsonFeatures = hotelGeoJsonData.features;
      name = hotelGeoJsonData.name;
      break;
    case "PokerRoom":
      geoJsonFeatures = porkerGeoJsonData.features;
      name = porkerGeoJsonData.name;
      break;
    case "Casino":
      geoJsonFeatures = casinoGeoJsonData.features;
      name = casinoGeoJsonData.name;
      break;
    case "Conveni":
      geoJsonFeatures = conveniGeoJsonData.features;
      name = conveniGeoJsonData.name;
      break;
    case "MoneyExchanger":
      geoJsonFeatures = moneyExchangerGeoJsonData.features;
      name = moneyExchangerGeoJsonData.name;
      break;
    default:
      geoJsonFeatures = ktvGeoJsonData.features;
      name = ktvGeoJsonData.name;
      break;
  }

  return (
    <Root>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(70% - ${drawerBleeding}px)`,
            overflow: "visible",
            bottom: `50px`,
          },
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        open={swipeableEdgeDrawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        // swipeAreaWidth={drawerBleeding - 50}
        disableDiscovery={true}
        disableSwipeToOpen={true}
        disableBackdropTransition={true}
        allowSwipeInChildren={true}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          {/* {list()} */}
          <div style={{ height: 50 }} onClick={toggleDrawer(true)}>
            <Puller />
          </div>

          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              position: "relative",
              overflow: "auto",
              height: "calc(70dvh - 60px)",
            }}
          >
            {geoJsonFeatures.map((data, idx) => {
              const properties = data.properties as unknown as {
                id: string;
                imageName: string;
                shopName: string;
                description: string;
                tripcomUrl: string;
              };

              return (
                <React.Fragment key={`${idx}${properties.id}`}>
                  <ListItem disablePadding>
                    <ListItemButton
                      alignItems="flex-start"
                      onClick={() => {
                        if (document.querySelectorAll(".leaflet-marker-icon")) {
                          document
                            .querySelectorAll(".leaflet-marker-icon")
                            .forEach((e) => e.classList.remove("serched-pin"));
                        }
                        setCenterOfMap(
                          data.geometry.coordinates
                            ? [
                                Number(data.geometry.coordinates[1]),
                                Number(data.geometry.coordinates[0]),
                              ]
                            : [14.571649689172614, 120.98437441430472]
                        );
                        setZoomOfMap(19);
                        setIsMoveCenter(true);
                        setPinId(`.${name}${properties.id}`);

                        const e = new Event("click", {
                          bubbles: true,
                          cancelable: true,
                        });
                        const pin = document.querySelector(
                          `img.leaflet-marker-icon.${name}${properties.id}`
                        ) as HTMLElement;
                        if (pin) {
                          pin.classList.add("serched-pin");
                          // pin.setAttribute("src", active_KTVPin);
                          // pin.dispatchEvent(e);
                        }
                        setSwipeableEdgeDrawerOpen(false);
                      }}
                    >
                      <ListItemAvatar key={`itemAvater_${properties.id}`}>
                        <Avatar
                          key={`avater_${idx}_${properties.id}`}
                          alt={"shop picture"}
                          src={`/images/${name}/${properties.imageName}.jpg`}
                          sx={{ width: 100, height: 75, marginRight: 3 }}
                          variant="rounded"
                        />
                        {/* <ImageWithPlaceholder
                          src={`/images/${name}/${properties.imageName}.jpg`}
                          alt={"shop picture"}
                          width={100}
                          height={75}
                        ></ImageWithPlaceholder> */}
                      </ListItemAvatar>
                      <ListItemText
                        key={`itemText_${idx}_${properties.id}`}
                        primary={properties.shopName}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {`${
                                properties.description
                                  ? properties.description
                                  : "-"
                              } `}
                              {/* <br /> */}
                              {footerBtnState === "Hotel" && (
                                <Container>
                                  <Row className="mb-2">
                                    <Button
                                      variant="outline-primary"
                                      size="sm"
                                      href={properties.tripcomUrl}
                                      target={"_blank"}
                                      rel={"noreferrer"}
                                      color={"blue!important"}
                                    >
                                      {" "}
                                      {"詳細"}
                                    </Button>
                                  </Row>
                                </Container>
                              )}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              );
            })}
          </List>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
};

export default SwipeableEdgeDrawer;
