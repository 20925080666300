import { useEffect } from "react";
import { useMap } from "react-leaflet";
import { ZoomPanOptions } from "leaflet";

type ChangeViewProps = {
  center: L.LatLngTuple;
  zoom: number;
  isMoveCenter: boolean;
  setIsMoveCenter: (isMoveCenter: boolean) => void;
  pinId: string;
};

const ChangeView: React.FC<ChangeViewProps> = ({
  center,
  zoom,
  isMoveCenter,
  setIsMoveCenter,
  pinId,
}) => {
  const map = useMap();
  useEffect(() => {
    if (isMoveCenter) {
      // map.setView(center, zoom);
      map.flyTo(center, zoom, {
        duration: 0.7,
        easeLinearity: 0.1,
      });
      setIsMoveCenter(false);
    }
  }, [center, zoom, isMoveCenter, setIsMoveCenter, map]);

  return null;
};
export default ChangeView;
