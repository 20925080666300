import React from "react";
import { Table } from "react-bootstrap";
import { LanguageSetting } from "../../common/Const";
import styled from "styled-components";
import moneyExchangerGeoJsonData from "../../geoJson/moneyExchanger.json";

type MoneyExchangerTableProps = {
  handleFilterShow: () => void;
  langSetting: LanguageSetting;
  serchedShopName: string;
  setCenterOfMap: (position: L.LatLngTuple) => void;
  setIsMoveCenter: (isMoveCenter: boolean) => void;
  setPinId: (id: string) => void;
  setZoomOfMap: (magnification: number) => void;
};

const MoneyExchangerTable: React.FC<MoneyExchangerTableProps> = ({
  handleFilterShow,
  langSetting,
  serchedShopName,
  setCenterOfMap,
  setIsMoveCenter,
  setPinId,
  setZoomOfMap,
}) => {
  return (
    <StyledTable striped borderless hover variant="dark" size="sm">
      <thead>
        <tr>
          <th>{langSetting === "JA" ? "店名" : "Shop name"}</th>
        </tr>
      </thead>
      <tbody>
        {moneyExchangerGeoJsonData.features
          .filter(
            (a: any) =>
              a.properties.shopName
                .toLowerCase()
                .indexOf(serchedShopName.toLowerCase()) > -1
          )
          .sort((a: any, b: any) => {
            if (a["properties"]["shopName"] < b["properties"]["shopName"])
              return 1;
            if (a["properties"]["shopName"] > b["properties"]["shopName"])
              return -1;
            return 0;
          })
          .map((row) => {
            return (
              <tr
                key={`tr${row.properties.id}`}
                onClick={() => {
                  if (document.querySelectorAll(".leaflet-marker-icon"))
                    document
                      .querySelectorAll(".leaflet-marker-icon")
                      .forEach((e) => e.classList.remove("serched-pin"));
                  setCenterOfMap(
                    row.geometry.coordinates
                      ? [
                          Number(row.geometry.coordinates[1]),
                          Number(row.geometry.coordinates[0]),
                        ]
                      : [14.571649689172614, 120.98437441430472]
                  );
                  setZoomOfMap(17);
                  setIsMoveCenter(true);
                  setPinId(`.MoneyExchanger${row.properties.id}`);

                  let e = new Event("click", {
                    bubbles: true,
                    cancelable: true,
                  });
                  let pin = document.querySelector(
                    `img.leaflet-marker-icon.MoneyExchanger${row.properties.id}`
                  ) as HTMLElement;
                  if (pin) {
                    pin.classList.add("serched-pin");
                    pin.dispatchEvent(e);
                  }
                  handleFilterShow();
                }}
              >
                <td key={`shopname${row.properties.id}`}>
                  {" "}
                  {row.properties.shopName.split("|||")[0]}
                  <span className="tag-for-search">
                    {row.properties.shopName.split("|||")[1]}
                  </span>
                </td>
              </tr>
            );
          })}
      </tbody>
    </StyledTable>
  );
};
const StyledTable = styled(Table)`
  thead {
    position: sticky;
    top: 0;
    left: 0;
  }
  tbody {
    overflow-x: hidden;
  }
  th {
    font-size: 0.55rem;
    font-weight: 800;
    width: 100px;
  }
  td {
    font-size: 0.6rem;
    width: 100px;
  }
`;

export default MoneyExchangerTable;
